import React, { useState, useEffect } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import {
  Button, Input, VStack, HStack, Box, Text, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, IconButton
} from '@chakra-ui/react';
import { InfoIcon, RepeatIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { AiOutlineStop } from 'react-icons/ai';

const GameOfLife = () => {
  const [grid, setGrid] = useState(new Map());
  const [running, setRunning] = useState(false);
  const cellSize = 20;
  const [stayAliveNeighbors, setStayAliveNeighbors] = useState('2,3');
  const [birthNeighbors, setBirthNeighbors] = useState('3');

  const getNeighbors = (x, y) => [
    [x - 1, y - 1], [x - 1, y], [x - 1, y + 1],
    [x, y - 1], [x, y + 1],
    [x + 1, y - 1], [x + 1, y], [x + 1, y + 1]
  ];

  const parseNeighbors = (str) => str.split(',').map(Number).filter(n => !isNaN(n));

  const updateGrid = () => {
    const stayAliveSet = new Set(parseNeighbors(stayAliveNeighbors));
    const birthSet = new Set(parseNeighbors(birthNeighbors));
    const newGrid = new Map();
    const cellsToConsider = new Set();

    grid.forEach((_, key) => {
      const [x, y] = key.split(',').map(Number);
      cellsToConsider.add(`${x},${y}`);
      getNeighbors(x, y).forEach(([nx, ny]) => cellsToConsider.add(`${nx},${ny}`));
    });

    cellsToConsider.forEach(key => {
      const [x, y] = key.split(',').map(Number);
      const neighbors = getNeighbors(x, y).filter(([nx, ny]) => grid.has(`${nx},${ny}`)).length;
      if (grid.has(key)) {
        if (stayAliveSet.has(neighbors)) {
          newGrid.set(key, true);
        }
      } else if (birthSet.has(neighbors)) {
        newGrid.set(key, true);
      }
    });

    setGrid(newGrid);
  };

  useEffect(() => {
    if (!running) return;
    const interval = setInterval(updateGrid, 100);
    return () => clearInterval(interval);
  }, [running, grid, stayAliveNeighbors, birthNeighbors]);

  const toggleCell = (x, y) => {
    const key = `${x},${y}`;
    const newGrid = new Map(grid);
    if (newGrid.has(key)) newGrid.delete(key);
    else newGrid.set(key, true);
    setGrid(newGrid);
  };

  const clearGrid = () => {
    setGrid(new Map());
  };

  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden', position: 'relative' }}>
      <TransformWrapper initialScale={1} minScale={0.5} maxScale={10} wheel={{ step: 0.1 }}>
        <TransformComponent>
          <Grid
            columnCount={1000}
            columnWidth={cellSize}
            height={window.innerHeight}
            rowCount={1000}
            rowHeight={cellSize}
            width={window.innerWidth}
            itemKey={({ columnIndex, rowIndex }) => `${columnIndex},${rowIndex}`}
          >
            {({ columnIndex, rowIndex, style }) => (
              <div
                style={{
                  ...style,
                  backgroundColor: grid.has(`${columnIndex},${rowIndex}`) ? '#18197A' : 'white',
                  border: 'solid 1px #ddd',
                  transition: 'background-color 0.3s',
                  cursor: 'pointer'
                }}
                onClick={() => toggleCell(columnIndex, rowIndex)}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = grid.has(`${columnIndex},${rowIndex}`) ? '#18197A' : '#B9C4FA'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = grid.has(`${columnIndex},${rowIndex}`) ? '#18197A' : 'white'}
              />
            )}
          </Grid>
        </TransformComponent>
      </TransformWrapper>

      <Box position="absolute" bottom="40px" left="50%" transform="translateX(-50%)">
        <HStack spacing={4}>
          <IconButton
            icon={<RepeatIcon />}
            aria-label="Clear"
            onClick={clearGrid}
            borderRadius="50%"
            size="lg"
          />
          <VStack align="start" bg="rgba(255, 255, 255, 0.8)" p={2} borderRadius="md">
            <Text>Neighbors for Life:</Text>
            <Input
              value={stayAliveNeighbors}
              onChange={(e) => setStayAliveNeighbors(e.target.value)}
              placeholder="Neighbors for life (comma separated)"
              size="sm"
              width="200px"
              bg="rgba(255, 255, 255, 0.8)"
            />
          </VStack>
          <VStack align="start" bg="rgba(255, 255, 255, 0.8)" p={2} borderRadius="md">
            <Text>Neighbors for Birth:</Text>
            <Input
              value={birthNeighbors}
              onChange={(e) => setBirthNeighbors(e.target.value)}
              placeholder="Neighbors for birth (comma separated)"
              size="sm"
              width="200px"
              bg="rgba(255, 255, 255, 0.8)"
            />
          </VStack>
          <IconButton
            icon={running ? <AiOutlineStop /> : <ArrowForwardIcon />}
            aria-label={running ? "Stop" : "Start"}
            onClick={() => setRunning(!running)}
            borderRadius="50%"
            size="lg"
          />
        </HStack>
      </Box>

      <Box position="absolute" top="10px" right="10px">
        <Popover>
          <PopoverTrigger>
            <IconButton icon={<InfoIcon />} />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Conway's Game of Life</PopoverHeader>
            <PopoverBody>
              <Text>
                Conway's Game of Life is a cellular automaton created in 1970. The game starts with an initial state and evolves in steps, with the following rules:
              </Text>
              <Text as="ul" pl={4}>
                <li>Any live cell with fewer than two live neighbors dies (underpopulation).</li>
                <li>Any live cell with two or three live neighbors lives on to the next generation.</li>
                <li>Any live cell with more than three live neighbors dies (overpopulation).</li>
                <li>Any dead cell with exactly three live neighbors becomes a live cell (reproduction).</li>
              </Text>
              <Text>
                On this site, you can modify these rules to your own desire by setting the number of neighbors that result in life or birth, any unused number of neighbors will be a death number.
              </Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </div>
  );
};

export default GameOfLife;
